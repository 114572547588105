$transLength: 0.6s;

.nav-route{

    position: absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;

    @keyframes fade-in {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }

    

    @keyframes slide-in {
        0%{
            transform: translateX(100%);
            opacity: 0;
        }
        100%{
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slide-out {
        0%{
            transform: translateX(0);
            opacity: 1;
        }
        100%{
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @keyframes slide-in-pop {
        0%{
            transform: translateX(-100%);
            opacity: 0;
        }
        100%{
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slide-out-pop {
        0%{
            transform: translateX(0);
            opacity: 1;
        }
        100%{
            transform: translateX(100%);
            opacity: 0;
        }
    }



    &.nav-route-in .nav-fade-in{
        animation: fade-in $transLength ease-in-out forwards;
    }
    &.nav-route-out .nav-fade-out{
        animation: fade-out $transLength ease-in-out forwards;
    }

    &.nav-route-out .nav-trans-target{
        z-index:2;
        animation: slide-out $transLength ease-in-out forwards;
    }

    &.nav-route-fade{

        &.nav-route-in{
            z-index:1;
            animation: fade-in $transLength ease-in-out forwards;
        }

        &.nav-route-out{
            z-index:2;
            animation: fade-out $transLength ease-in-out forwards;
        }

    }

    &.nav-route-slide{

        &.nav-route-in{
            z-index:1;
            animation: slide-in $transLength ease-in-out forwards;
        }

        &.nav-route-out{
            z-index:2;
            animation: slide-out $transLength ease-in-out forwards;
        }

    }

    &.nav-route-slide-target{

        &.nav-route-in .nav-trans-target{
            z-index:1;
            animation: slide-in $transLength ease-in-out forwards;
        }

        &.nav-route-out .nav-trans-target{
            z-index:2;
            animation: slide-out $transLength ease-in-out forwards;
        }

    }
}

.history-pop .nav-route{
    &.nav-route-slide{

        &.nav-route-in{
            animation: slide-in-pop $transLength ease-in-out forwards;
        }

        &.nav-route-out{
            animation: slide-out-pop $transLength ease-in-out forwards;
        }

    }

    &.nav-route-slide-target{

        &.nav-route-in .nav-trans-target{
            animation: slide-in-pop $transLength ease-in-out forwards;
        }

        &.nav-route-out .nav-trans-target{
            animation: slide-out-pop $transLength ease-in-out forwards;
        }

    }
}