@import "../CommonJs/NavTransitions.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html, body, #root{
  margin:0;
  padding:0;
  width:100%;
  height:100%;
  overflow: hidden;
}

#root{
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  display:flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
